<template>
  <div class="login-page">
    <div class="login-container">
      <!-- Left side -->
      <section class="login-left-panel">
        <div class="brand-container">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/e830e5794ba84973b9eb690ba825e1f8/ed8f0e4b78af6981dc564cf292e34db4c3154fb9d80ffc894c9ad1b1559a83b8?apiKey=e830e5794ba84973b9eb690ba825e1f8" alt="Nexlaw Logo" class="brand-logo" />
          <h1 class="hero-title">Few Taps Away from Effortless Litigation with AI</h1>
        </div>
        <div class="hero-image-container">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/e830e5794ba84973b9eb690ba825e1f8/31f77cd9ae320dcf3aab13a5dc918411eb2d1614a7e993519c1b1c72d1477f1a?apiKey=e830e5794ba84973b9eb690ba825e1f8" alt="Legal AI Dashboard Preview" class="hero-image" />
        </div>
      </section>

      <!-- Right side -->
      <div class="login-right-panel">
        <div class="login-form">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/e830e5794ba84973b9eb690ba825e1f8/c11fa8882a9fb985e8d63451dc8b5fcdc334237138647af0abb57e20c84c7e10?apiKey=e830e5794ba84973b9eb690ba825e1f8" alt="Welcome Icon" class="welcome-icon" />
          <p class="title">Welcome back!</p>
          <p>Log in to your account</p>
          
          <form class="login">
            <!-- Email input group -->
            <div class="input-group" :class="{'border_error': errors.has('email')}">
              <span class="material-symbols-rounded icon email-icon">email</span>
              <input 
                autocorrect="off" 
                autocomplete="off" 
                name="email" 
                v-validate="'required|email'" 
                type="email" 
                placeholder="Enter Email" 
                v-model="email" 
                required
              />
            </div>
            <!-- Password input group -->
            <div class="input-group d-flex align-items-center" :class="{'border_error': errors.has('password')}">
              <span class="material-symbols-rounded icon lock-icon">lock</span>
              <input 
                v-if="showPass" 
                autocorrect="off" 
                autocomplete="off" 
                name="password" 
                v-validate="'required'" 
                v-model="password" 
                type="text" 
                placeholder="Enter Password" 
                @keyup.enter="loginJWT" 
                required
              />
              <input 
                v-else 
                autocorrect="off" 
                autocomplete="off" 
                name="password" 
                v-validate="'required'" 
                v-model="password" 
                type="password" 
                placeholder="Enter Password" 
                @keyup.enter="loginJWT" 
                required
              />
              <!-- Toggle password visibility -->
              <span 
                @click="showPassword" 
                class="material-symbols-rounded icon visibility-toggle-icon">
                {{ showPass ? 'visibility' : 'visibility_off' }}
              </span>
            </div>
            <!-- Forgot password link -->
            <a href="" @click.prevent="forget" class="forgot-password">Forgot Password?</a>

            <!-- Login button -->
            <button 
              @click="loginJWT" 
              type="button" 
              :class="loginButtonClass" 
              :disabled="disabled"
              @mouseover="buttonHover = true"
              @mouseleave="buttonHover = false"
            >
              Log in
            </button>
          </form>
          <footer>
            Don’t have an account? <a href=""> Contact our Sales Team </a>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Auth from "@/store/Auth.js";
import jwt_decode from "jwt-decode";
import axios from "@/axios.js";

export default {
  data() {
    return {
      email: null,
      errorMessage: "",
      error: false,
      password: null,
      showPass: false,
      disabled: true,
      status: "not_accepted",
      isBetaUser: false,
    };
  },

  computed: {
    loginButtonClass() {
      return {
        'login-button': !this.email || !this.password || this.errors.has('email') || this.errors.has('password'),
        'login-button-valid': this.email && this.password && !this.errors.has('email') && !this.errors.has('password')
      }
    }
  },

  methods: {
    createAcc() {
      this.$router.push("/Creating-Account");
    },

    showPassword() {
      this.showPass = !this.showPass;
    },

    loginWithGoogle() {
      if (location.href.includes("www.platform.nexlaw.ai")) {
        window.location.href =
          "https://api.platform.nexmind.ai/google/redirect/nexlaw";
      } else {
        window.location.href =
          "https://app.nexmind.nexodn.com/google/redirect/nexlaw";
      }
    },

    loginWithMicrosoft() {
      if (location.href.includes("www.platform.nexlaw.ai")) {
        window.location.href =
          "https://api.platform.nexmind.ai/microsoft/redirect/nexlaw";
      } else {
        window.location.href =
          "https://app.nexmind.nexodn.com/microsoft/redirect/nexlaw";
      }
    },

    validation() {
      // //
      if (this.email == null || this.email == "") {
        // //
        this.errorMessage = "Email required.";
        this.error = true;
        return true;
      } else if (!this.validEmail()) {
        // //
        this.errorMessage = "Invalid email format";
        this.error = true;
        return true;
      } else {
        this.error = false;
        return false;
      }
    },

    validEmail() {
      // //
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },

    loginJWT() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.disabled = true;
          const payload = {
            email: this.email,
            password: this.password,
          };

          // console.log("Status: ", this.status);
          // console.log("isBetaUser: ", this.isBetaUser);

          Auth.loginJWT(payload)
            .then((response) => {
              this.$toast.success(response.data.message);
              // localStorage.setItem("betaUserAgreement", this.status);

              // this.$bvToast.toast(response.data.message, {
              //   title: "Success",
              //   variant: "success",
              //   toaster: "b-toaster-bottom-left",
              //   solid: true,
              // });

              let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: true,
                onCancel: this.onCancel,
              });

              if (localStorage.userInfo == undefined) {
                var timer = setInterval(() => {
                  if (localStorage.userInfo != undefined) {
                    clearInterval(timer);
                    this.$router.push({ name: "Overview" });
                    loader.hide();
                  }
                }, 5000);
              } else {
                this.$router.push({ name: "Overview" });
                loader.hide();
              }
            })
            .catch((error) => {
              console.log(error);
            });
          this.disabled = false;
        }
      });
    },

    forget() {
      this.$router.push({ name: "ForgotPasswordInput" });
    },

    // openAgreement() {
    //   this.$bvModal.show("open-beta-user-agreement");
    // },
  },

  created() {
    let loader = this.$loading.show({
      // Optional parameters
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: true,
      onCancel: this.onCancel,
    });

    // if (localStorage.getItem("betaUserAgreement") === "accepted") {
    //   this.isBetaUser = true;
    //   this.status = "accepted";
    // }

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    // console.log(urlParams);
    // console.log(token);

    if (token) {
      localStorage.setItem("accessToken", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      console.log(axios.defaults.headers.common["Authorization"]);
      axios
        .get("me")
        .then((res) => {
          loader.hide();
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          localStorage.setItem(
            "permissions",
            JSON.stringify(res.data.data.permissions)
          );
          const thisUser = res.data.data;
          let role = "editor";
          Object.assign(thisUser, { userRole: role });
          axios
            .get("locations")
            .then((res) => {
              var locations = [];
              res.data.data.forEach((element, index) => {
                // if (element.name == "www.google.com.my") {
                //   locations.push(element);
                // } else if (element.name == "www.google.com.au") {
                //   locations.push(element);
                // } else if (element.name == "www.google.com.sg") {
                //   locations.push(element);
                // }
                locations.push(element);
              });

              localStorage.setItem("locations", JSON.stringify(locations));
            })
            .catch((error) => {
              reject(error);
            });

          this.$router.push({ name: "Overview" });
        })
        .catch((error) => {
          console.log(error);
          this.disabled = false;
          loader.hide();
        });
    } else if (localStorage.getItem("accessToken")) {
      loader.hide();

      if (
        localStorage.accessToken == undefined ||
        localStorage.accessToken == "undefined"
      ) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userInfo");
        router.push("/login");
      } else {
        const token = localStorage.getItem("accessToken");
        const token_broken = jwt_decode(token);
        if (Date.now() < token_broken.exp * 1000) {
          loader.hide();
          axios
            .get("me")
            .then((res) => {
              loader.hide();
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));
              localStorage.setItem(
                "permissions",
                JSON.stringify(res.data.data.permissions)
              );
              const thisUser = res.data.data;
              let role = "editor";
              Object.assign(thisUser, { userRole: role });

              axios
                .get("locations")
                .then((res) => {
                  var locations = [];
                  res.data.data.forEach((element, index) => {
                    // if (element.name == "www.google.com.my") {
                    //   locations.push(element);
                    // } else if (element.name == "www.google.com.au") {
                    //   locations.push(element);
                    // } else if (element.name == "www.google.com.sg") {
                    //   locations.push(element);
                    // }
                    locations.push(element);
                  });

                  localStorage.setItem("locations", JSON.stringify(locations));
                })
                .catch((error) => {
                  reject(error);
                });

              this.$router.push({ name: "Overview" });
            })
            .catch((error) => {
              console.log(error);
              loader.hide();
            });
        } else {
          this.disabled = false;
        }
      }
    } else {
      loader.hide();
      this.disabled = false;
    }
  },
};
</script>
<style scoped>
.login-page {
  min-height: 100vh;
}

.left-panel {
  position: relative;
  height: 100vh;
  background: linear-gradient(to bottom, var(--dark-blue) 75%, #0E4485 25%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 80px 20px 20px 60px;
  margin: 10px;
}

.left-panel p {
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
  margin-top: 72px;
  width: 408px;
}

.right-panel {
  background: white;
  padding: 20px;
  width: 650px;
}

.login-form {
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.login-form .title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 5px;
}

.login-form p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 30px;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.input-group {
  width: 500px;
  height: 56px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: var(--interface-grey);
  margin-bottom: 16px;
  position: relative;
}

.input-group input {
  flex-grow: 1;
  border: none;
  padding: 12px 0;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--neutral-black);
  background-color: var(--interface-grey);
}
input::placeholder {
  color: var(--dark-grey);
  opacity: 1;
}

.input-group .icon {
  width: 20px;
  height: 20px;
  color: var(--dark-grey);
  display: flex;
  align-items: center;
  margin-right: 8px;
}


.input-group .email-icon {
  margin-right: 12px;
}

.input-group .visibility-toggle-icon {
  margin-left: auto;
  cursor: pointer;
}

.forgot-password {
  text-decoration: none;
  margin-bottom: 20px;
  align-self: flex-end;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color:var(--primary);
}

.login-button {
  width: 500px;
  height: 40px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px;
  color: var(--mid-grey);
  background: #0E44851A;
}

.login-button-valid {
  width: 500px;
  height: 40px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px;
  color: #ffffff;
  background: var(--Primary-Blue, #0E4485);
}

.login-button-valid:hover {
  background: var(--Primary-Dark-Blue, #000046);
  color: #ffffff;
}

.border_error {
  outline: 1px solid var(--status-red);
  background-color: rgba(255, 77, 79, 0.10);
}

.border_error input {
  background-color: rgba(255, 77, 79, 0);
}

.border_error .email-icon, .border_error .lock-icon {
  color: var(--status-red);
}

footer {
  margin-top: 100px;
  bottom: 0;
}
footer a {
  color: #0E4485;
  outline: none;
  text-decoration: none;
}

.login-page {
  background: #fff;
  overflow: hidden;
  padding: 20px;
}

.login-container {
  display: flex;
  gap: 20px;
}

.login-left-panel {
  width: 50%;
  border-radius: 16px;
  background-color: #000046;
  padding: 80px 0 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.brand-container {
  margin-left: 80px;
  width: 408px;
  max-width: 100%;
}

.brand-logo {
  width: 100px;
  aspect-ratio: 2.63;
  object-fit: contain;
}

.hero-title {
  margin-top: 45px;
  color: #fff !important;
  font: 600 39px Poppins, sans-serif !important;
}

.hero-image-container {
  background-color: #0e4485;
  margin-top: 275px;
  padding: 0 80px;
  display: flex;
  justify-content: flex-end;
}

.hero-image {
  width: 650px;
  aspect-ratio: 1.29;
  border-radius: 4px;
  margin-top: -193px;
  margin-right: -85px;
  object-fit: contain;
}

.login-right-panel {
  width: 50%;
  margin-left: 20px;
}

.welcome-icon {
  width: 80px;
  aspect-ratio: 0.7;
  object-fit: contain;
}
</style>
