<template>
  <div>
    <Login></Login>
  </div>
</template>

<script>
import Login from './lo.vue';

export default {
  components: { Login, },
}
</script>

<style scoped>

</style>